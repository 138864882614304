<template>
  <div class="btn-group">
    <button
      id="all"
      :class="['btn', 'btn-primary', filterPage === 'all' && 'active']"
      aria-current="page"
      @click="(event) => changeStatusPage(event.target.id)"
    >
      All
    </button>
    <button
      id="todo"
      :class="['btn', 'btn-primary', filterPage === 'todo' && 'active']"
      @click="(event) => changeStatusPage(event.target.id)"
    >
      To Do
    </button>
    <button
      id="done"
      :class="['btn', 'btn-primary', filterPage === 'done' && 'active']"
      @click="(event) => changeStatusPage(event.target.id)"
    >
      Done
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState("todo", { filterPage: "filterPage" }),

  methods: {
    changeStatusPage(currentFilterPage) {
      this.$store.dispatch("todo/updateFilterPage", currentFilterPage);
    },
  },
};
</script>

<style scoped></style>
