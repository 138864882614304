<template>
  <ul class="list-group list-group-flush my-3">
    <slot></slot>
  </ul>
</template>

<script>
export default {};
</script>

<style scoped></style>
