<template>
  <section class="input-group mb-3">
    <input
      type="text"
      placeholder="Add a to do..."
      aria-label="To do input field with button"
      aria-describedby="submitButton"
      class="form-control"
      v-model="userInput"
      v-on:keyup.enter="addToDo(userInput)"
    />
    <button
      @click="addToDo(userInput)"
      type="button"
      id="submitButton"
      class="btn btn-outline-secondary"
    >
      Add
    </button>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      userInput: "",
    };
  },

  computed: mapState("todo", ["filterPage"]),

  methods: {
    addToDo(userInput) {
      if (this.userInput) {
        this.$store.dispatch("todo/addToDo", userInput);
        this.userInput = "";
        if (this.filterPage === "done") {
          this.$store.dispatch("todo/updateFilterPage", "all");
        }
      }
    },
  },
};
</script>

<style scoped></style>
