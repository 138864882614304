<template>
  <div>
    <span><slot name="name">Name</slot></span>
    <span class="badge bg-danger"><slot name="count">0</slot></span>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
