<template>
  <div>
    <!-- header component -->
    <header class="bg-primary fs-1 p-3 text-white">Vue To Do Cli</header>
    <!-- counters -->
    <section class="d-flex flex-column align-items-end mt-3 mb-4">
      <ToDoCounter
        ><template slot="name">To Do </template
        ><template slot="count">{{ todoCount }}</template></ToDoCounter
      >

      <ToDoCounter
        ><template slot="name">Done </template
        ><template slot="count">{{ doneCount }}</template></ToDoCounter
      >
    </section>

    <!-- filter -->
    <ToDoFilter />

    <!-- to do list -->
    <ToDoList>
      <ToDoListItem
        v-for="todo in filterPageList"
        :key="todo.id"
        :id="todo.id"
        :status="todo.status"
        >{{ todo.item }}
      </ToDoListItem>
    </ToDoList>

    <!-- footer -->
    <footer>
      <ToDoInput />
      <button
        type="button"
        class="btn btn-link text-danger"
        @click="clearCompleted"
      >
        Clear Completed
      </button>
    </footer>
  </div>
</template>

<script>
import ToDoCounter from "../components/ToDo/ToDoCounter";
import ToDoInput from "../components/ToDo/ToDoInput";
import ToDoList from "../components/ToDo/ToDoList";
import ToDoListItem from "../components/ToDo/ToDoListItem";
import ToDoFilter from "../components/ToDo/ToDoFilter";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    ToDoCounter,
    ToDoList,
    ToDoInput,
    ToDoListItem,
    ToDoFilter,
  },

  methods: {
    clearCompleted() {
      this.$store.dispatch("todo/clearCompletedToDos");
    },
  },

  computed: {
    ...mapState("todo", { todos: "todos", page: "filterPage" }),
    ...mapGetters({
      todoCount: "todo/todoLength",
      doneCount: "todo/doneLength",
      filterPageList: "todo/filterPageList",
    }),
  },

  // watch changes on todos
  watch: {
    todos: {
      deep: true,
      handler(newToDo) {
        localStorage.todos = JSON.stringify(newToDo);
      },
    },
    page(newFilterPage) {
      localStorage.filterPage = JSON.stringify(newFilterPage);
    },
  },

  // when components mounts
  mounted() {
    if (localStorage.todos || localStorage.filterPage) {
      this.$store.dispatch("todo/getLocalStorage", localStorage);
    }
  },
};
</script>

<style scoped></style>
