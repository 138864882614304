<template>
  <li class="list-group-item p-2 d-flex justify-content-between">
    <div>
      <BIconCircle
        @click="changeStatusToDo(id)"
        v-show="status === 'todo'"
        style="cursor: pointer"
      />
      <BIconCheckCircle
        style="cursor: pointer"
        @click="changeStatusToDo(id)"
        v-show="status === 'done'"
      />
      <span
        :style="[status === 'done' && { textDecoration: 'line-through' }]"
        class="mx-2 d-inline-block"
        ><slot>Item</slot></span
      >
    </div>
    <button
      type="button"
      aria-label="Close"
      class="btn-close btn-sm"
      @click="deleteToDo(id)"
    ></button>
  </li>
</template>

<script>
export default {
  props: {
    id: String,
    status: String,
  },

  methods: {
    deleteToDo(id) {
      this.$store.dispatch("todo/deleteToDo", id);
    },

    changeStatusToDo(id) {
      this.$store.dispatch("todo/updateStatusToDo", id);
    },
  },
};
</script>

<style scoped></style>
